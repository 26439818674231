import React from 'react'
import TransporterFilterTab from './TransporterFilterTab'
import { Table, Image, Form } from 'react-bootstrap'
import TransporterTable from '../ui/TransporterTable'
import { _filterConnaissement2 } from '../../utils/api/apiControlerFunctions'
import TableLoader from '../ui/Loader/TableLoader'
import TableTitleTransporter from './TableTitleTransporter'
import userStore, { UserState } from '../../stores/userStore'

export default function TableTransporter({ tableTransporterProps }: any) {
  const {
    isLoading,
    connaissementData,
    setSelectedConnaissement,
    handleShowDetailConnaiss,
    handleShowQrcode,
    isError,
    isFiltering,
    isEmpty,
    noResult,
    status,
    filteringData,
    setFilteringData,
    setIsLoading,
    tokenTab,
    currentPage,
    setIsError,
    setConnaissementData,
    itemPerPage,
    setTotalPages,
    setIsFiltering,
    sortConfig,
    setSortConfig,
  } = tableTransporterProps

  const dataStore = userStore((state: UserState) => state)

  const billOfTableTitleProps = { sortConfig, setSortConfig }

  const billOfilterProps = {
    filteringData,
    setFilteringData,
    isFiltering,
    isEmpty,
    isLoading,
  }

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault()
        _filterConnaissement2(
          status,
          filteringData,
          setIsLoading,
          tokenTab,
          currentPage,
          setIsError,
          setConnaissementData,
          itemPerPage,
          setTotalPages,
          setIsFiltering,
          sortConfig,
          isEmpty,
          dataStore
        )
      }}
    >
      <Table
        striped
        hover
        responsive
        className='transport-table responsive-font-small border w-100'
      >
        <TableTitleTransporter billOfTableTitleProps={billOfTableTitleProps} />
        <TransporterFilterTab billOfilterProps={billOfilterProps} />
        <tbody>
          {!isLoading &&
            connaissementData?.length > 0 &&
            connaissementData?.map((connaissement: any, indx: number) => {
              return (
                <React.Fragment key={indx}>
                  {connaissement?.dernierEtat?.evenementConnaissement === status && (
                    <TransporterTable
                      setSelectedConnaissement={setSelectedConnaissement}
                      connaissement={connaissement}
                      handleShowDetailConnaiss={handleShowDetailConnaiss}
                      handleShowQrcode={handleShowQrcode}
                    />
                  )}
                </React.Fragment>
              )
            })}
          {isLoading &&
            Array.from({ length: 5 }).map((_: any, index: number) => (
              <TableLoader colNumber={5} key={index} />
            ))}
          {!isLoading && !isError.error && isFiltering && connaissementData?.length === 0 && (
            <tr className=''>
              <td></td>
              <td colSpan={7} className='text-center'>
                <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
              </td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
    </Form>
  )
}
