import { create } from 'zustand';
import { statusType } from '../../definitions/statusType';
import { createJSONStorage, persist } from 'zustand/middleware';

export type TransportState = {
    title: string
    status: statusType
    borderColor: string
    setTransporterDataStore: (data: any) => void
    resetAll: () => void
}
export type TransportDataType = {
    title: string
    status: statusType
    borderColor: string
}


const useTransporterDataStore = create(
    persist<TransportState>(
    (set) => ({
    title: 'DEMANDE',
    status: 'DEMANDE',
    borderColor: 'cyan',

    setTransporterDataStore: (data: TransportDataType) => set((state: TransportState) => {
        return {
            ...state,
            title: data.title,
            status: data.status,
            borderColor: data.borderColor
        }
    }),

    resetAll: () => set({ title: 'DEMANDE',
        status: 'DEMANDE',
        borderColor: 'cyan',}),
        
    }
    ),
     {
          name: "transport", // unique name
          storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage sessionStorage' is used
        }
))

export default useTransporterDataStore;