import React, { SetStateAction } from "react"
import { errorType } from "../../definitions/errorType"
import ConnaissementServices from "../../services/connaissements/ConnaissementServices"
import IlesService from "../../services/IlesService"
import NaviresService from "../../services/navires/NaviresService"
import TrajetsService from "../../services/TrajetsService"
import userStore, { UserState } from "../../stores/userStore"
import { statusType } from "../../definitions/statusType"
import { ResultConnaissementType } from "../../definitions/ConnaissementType"
import NomenclaturesService from "../../services/nomenclatures/NomenclaturesService"
import { NomenclatureType } from "../../definitions/NomenclatureType"
import { PDFDocument } from 'pdf-lib';


/////////////////////
//get Navire list
/////////////////////
export const _getShipments = async (
  token: string,
  dataStore: any,
  setNaviresData: React.Dispatch<
    React.SetStateAction<{
      id: number
      name: string
    }[]>
  >
) => {
  try {
    const response = await NaviresService.getNaviresList(token)
    const navires = response.data?.map((nav: any) => {
      return {
        id: nav.id,
        name: nav.nom,
      }
    })
    setNaviresData(navires)
  } catch (error: any) {
    console.log(error)
    if (error?.response?.data?.error === 'invalid_token') {
      const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
      _refreshToken(dataStore?.token, idCompany)
    }
  }
}

/////////////////////
//Planning controller
/////////////////////

export const _getIslandByName = async (name: string, setListIsland: any) => {
    try {
      if(name?.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response?.data?.length > 0) {
        setListIsland(response.data)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }

export const _getAIslandByName = async (name: string, setIsland: any) => {
    try {
      if(name?.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response?.data?.length > 0) {
        setIsland(response.data?.[0]?.nom)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }

export  const __getTrajetByIslandId = async (
    idIle: string | null,
    page: number,
    limit: number,
    dateDebut: string,
    dateFin: string,
    nameNavire: string,
    setTrajetData: any,
    setSearchIslandName: any
  ) => {
    try {
      const response = await TrajetsService.getTrajetByIslandId(
        idIle,
        page,
        limit,
        dateDebut,
        dateFin
      )
      const filteredData = response.data?.content.filter(
        (navire: any) => navire.nomNavire === nameNavire
      )
      
      if (filteredData?.length > 0) {
        setTrajetData(filteredData)
        setSearchIslandName('')
      } else {
        setTrajetData(response.data?.content)
        setSearchIslandName('')
      }
    } catch (error) {
      console.log(error)
    }
  }

export const _getPlanningOfNavire = async (
    idNavire: string | null,
    page: string,
    limit: string,
    dateDebut: string,
    dateFin: string,
    setSelectedTrajet: any,
    setTrajetData: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsError: React.Dispatch<React.SetStateAction<errorType>>
  ) => {
    setIsLoading(true)
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await TrajetsService.getTrajetByNavireId(
        idNavire,
        page,
        limit,
        dateDebut,
        dateFin
      )

      if(response.data.content?.length === 0 ){
        setIsLoading(false)
        setIsError({
          error: true,
          message: "Aucun trajet trouvé",
        })
        setSelectedTrajet(undefined)
        setTrajetData(undefined)

      }
      else if (response.data.content.length > 0) {
        setSelectedTrajet(undefined)
        setTrajetData(response.data.content)
        setIsLoading(false)

      }
    } catch (error: any) {
      console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })

    }
  }

export const _getPlanningMooz = async (
  idNavire: number,
  page: string,
  limit: string,
  dateDebut: string,
  dateFin: string,
  setSelectedTrajet: any,
  setTrajetData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>,
  ileArrivee: string,
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: "",
  })
  try {
    const responseIsland = await IlesService.getIslandByName(ileArrivee)

    const response = await TrajetsService.getPlanningMoorea(idNavire, dateDebut, dateFin, 29)
    
    if (response.data?.length === 0) {
      setIsError({
        error: true,
        message: "Aucun trajet trouvé",
      })
      setSelectedTrajet(undefined)
      setTrajetData(undefined)
      setIsLoading(false)
    } else if (response.data.length > 0) {
      const arrivee = response.data?.filter((trajet: any) => trajet?.destinationArrivee?.toLowerCase().includes(ileArrivee.toLowerCase()))
  
      
      if (responseIsland?.data?.length === 0) {
        setTrajetData(response.data.content)
      } else {
        if (arrivee?.length === 0) {
          setIsLoading(false)
          setIsError({
            error: true,
            message: 'Ce navire ne touche pas votre île de destination.',
          })
          setTrajetData(undefined)
        } else {
          setSelectedTrajet(undefined)
          setTrajetData(response.data)

        }}

      setIsLoading(false)
    }
  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    const errorMessage = error?.response?.data?.message === "Erreur d'accès aux données" ? "Vérifiez les dates de début et de fin de la période" : error?.response?.data?.message
    setIsError({
      error: true,
      message: errorMessage,
    })
  }
}

export  const _getTrajetByIslandId = async (
  idIle: string | null,
  page: number,
  limit: number,
  dateDebut: string,
  dateFin: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsError: React.Dispatch<React.SetStateAction<errorType>>,
  setTrajetIslandData: any,
  initIleArrivee: string,
  searchIleArrivee: string,
  setSelectedTrajet: any,
) => {
  setIsLoading(true)
  setIsError({
    error: false,
    message: '',
  })
  setTrajetIslandData([])
  setSelectedTrajet(undefined)
    
  try {
    let responseIsland: any = undefined
    if (initIleArrivee !== '') {
      responseIsland = await IlesService.getIslandByName(initIleArrivee)
    }
    const responseSearchIsland =  await IlesService.getIslandByName(searchIleArrivee)
   
    
    setIsLoading(false)
    

      if (responseSearchIsland?.data?.length === 0) {
        setIsError({
          error: true,
          message: "L'île choisie n'existe pas",
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)
      }else if (responseIsland?.data?.[0]?.id !== responseSearchIsland?.data?.[0]?.id && responseIsland?.data?.[0]?.id !== undefined) {
        setIsError({
          error: true,
          message: "Attention l'île choisie n'est pas la même que celle du destinataire",	
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)

      }else {
        const response = await TrajetsService.getTrajetByIslandId(
          responseSearchIsland?.data?.[0]?.id,
          page,
          limit,
          dateDebut,
          dateFin
        )
        if (response.data.content?.length === 0) {
          setIsError({
            error: true,
            message: "Aucun trajet trouvé, vérifiez les dates de début et de fin de la période",
          })
          setTrajetIslandData(undefined)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          setTrajetIslandData(response.data?.content)
          
          setIsError({
            error: false,
            message: "",
          })
        }
  
      }
    // }

  
    
    setIsLoading(false)
    

      if (responseSearchIsland?.data?.length === 0) {
        setIsError({
          error: true,
          message: "L'île choisie n'existe pas",
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)
      }else if (responseIsland?.data?.[0]?.id !== responseSearchIsland?.data?.[0]?.id && responseIsland?.data?.[0]?.id !== undefined) {
        setIsError({
          error: true,
          message: "Attention l'île choisie n'est pas la même que celle du destinataire",	
        })
        setIsLoading(false)
        setTrajetIslandData(undefined)

      }else {
        const response = await TrajetsService.getTrajetByIslandId(
          responseSearchIsland?.data?.[0]?.id,
          page,
          limit,
          dateDebut,
          dateFin
        )
        if (response.data.content?.length === 0) {
          setIsError({
            error: true,
            message: "Aucun trajet trouvé, vérifiez les dates de début et de fin de la période",
          })
          setTrajetIslandData(undefined)
          setIsLoading(false)
        }else{
          setIsLoading(false)
          setTrajetIslandData(response.data?.content)
          setIsError({
            error: false,
            message: "",
          })
        }
  
      }
    // }

  } catch (error: any) {
    console.log(error)
    setIsLoading(false)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })
  }
}

/////////////////////
/// Refresh token 
/////////////////////

export const _refreshToken = async (access_token: string | null, id: number) => {
  const newCompany = userStore.getState().company
  try {
    const response = await ConnaissementServices.getrefreshToken(access_token, id)
    const updatedData = newCompany.map((company: any) =>
      company.id_company === id
        ? { ...company, access_token: response?.data?.access_token }
        : company
    );
    console.log(newCompany)
    userStore
      .getState()
      .authLogin(
        null,
        null,
        null,
        null,
        "",
        updatedData,//company
        null,
        null,
        null,
        updatedData?.[0]?.access_token,
      )
      window.location.reload();
  } catch (error) {
    console.log(error)
  }
}

/////////////////////
///Connaissement
/////////////////////


export const _connaissementDataTablUser = async (
  status: statusType,
  currentPage: number,
  setTotalPages: any,
  itemPerPage: number,
  setIsLoading: any,
  setIsError: any,
  setConnaissementData: any,
  setIsErrorConnaiss: any,
  dataStore: any,
) => {
  
  setIsLoading(true)
    try {
      const response = await ConnaissementServices.getConnaissement(
        dataStore.access_token,
        currentPage,
        itemPerPage
      )
      if (response?.status === 200) {
        setIsErrorConnaiss({
          error: false,
          message: '',
        })
      }
      const sortedData = response?.data?.content?.sort((a: any, b: any) => b.id - a.id)
      // console.log(response?.data)
      setTotalPages(response?.data?.totalPages)
      setConnaissementData(sortedData)
      setIsLoading(false)
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Une erreur est survenue. Veuillez réessayer plus tard." : error?.message
      })
      setIsLoading(false)

      if (error?.response?.data?.error === 'invalid_token') {
        setIsError({
          error: true,
          message: error?.response?.data?.error
        })
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
}

export const _connaissementDataTable = async (
  status: statusType,
  currentPage: number,
  setTotalPages: any,
  itemPerPage: number,
  setIsLoading: any,
  tokenTab: string[],
  setIsError: any,
  setConnaissementData: any,
  setIsErrorConnaiss: any,
  dataStore: any,
) => {
  setIsLoading(true);
  let newSortData: any[] = [];
  let pageTotal: any[] = [];

  try {
    const connaissementPromises = tokenTab.map(async (token: string) => {

      try {
        const response = await ConnaissementServices.getTransporterByStatus2(token, currentPage, itemPerPage, status);
        newSortData = [...newSortData, ...response?.data?.content];
        pageTotal.push(response?.data?.totalPages);
      } catch (error: any) {
        console.log(error);
        setIsError({
          error: true,
          message: error?.response?.data?.message,
        });

        if (error?.response?.data?.error === 'invalid_token') {
          
          const company = dataStore?.company?.find((company: any) => company.access_token === token);
          if (company) {
            console.log(company.name)
            await _refreshToken(dataStore?.token, company.id_company);
          }
        }
      }
    });

    await Promise.all(connaissementPromises);

    const sortedData = newSortData.sort((a: any, b: any) => b.id - a.id);
    setTotalPages(Math.max(...pageTotal));
    setConnaissementData(sortedData);
    setIsLoading(false);
    setIsErrorConnaiss({
      error: false,
      message: '',
    });
  } catch (error: any) {
    console.log(error);
    setIsError({
      error: true,
      message: error?.response?.data?.error
        ? error?.response?.data?.error
        : error?.message === 'Network Error'
        ? 'Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard.'
        : error?.message,
    });
    setIsLoading(false);

  }
};
export const _connaissementDataTable2 = async (
  status: statusType,
  currentPage: number,
  setTotalPages: any,
  itemPerPage: number,
  setIsLoading: any,
  tokenTab: string[],
  setIsError: any,
  setConnaissementData: any,
  setIsErrorConnaiss: any,
  dataStore: any,
) => {
  setIsLoading(true);
  let newSortData: any[] = [];
  let pageTotal: any[] = [];

  try {
    const connaissementPromises = tokenTab.map(async (token: string) => {

      try {
        const response = await ConnaissementServices.getTransporterByStatus(token, currentPage, itemPerPage, status);
        newSortData = [...newSortData, ...response?.data?.content];
        pageTotal.push(response?.data?.totalPages);
      } catch (error: any) {
        console.log(error);
        setIsError({
          error: true,
          message: error?.response?.data?.message,
        });

        if (error?.response?.data?.error === 'invalid_token') {
          
          const company = dataStore?.company?.find((company: any) => company.access_token === token);
          if (company) {
            console.log(company.name)
            await _refreshToken(dataStore?.token, company.id_company);
          }
        }
      }
    });

    await Promise.all(connaissementPromises);

    const sortedData = newSortData.sort((a: any, b: any) => b.id - a.id);
    setTotalPages(Math.max(...pageTotal));
    setConnaissementData(sortedData);
    setIsLoading(false);
    setIsErrorConnaiss({
      error: false,
      message: '',
    });
  } catch (error: any) {
    console.log(error);
    setIsError({
      error: true,
      message: error?.response?.data?.error
        ? error?.response?.data?.error
        : error?.message === 'Network Error'
        ? 'Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard.'
        : error?.message,
    });
    setIsLoading(false);

  }
};

export const _filterConnaissement = async (
  event: any | undefined,
  status: statusType,
  filteringData: any,
  setIsLoading: any,
  tokenTab: string[],
  currentPage: number,
  setIsError: React.Dispatch<
    React.SetStateAction<{
      error: boolean
      message: string
    }>
  >,
  setConnaissementData: any,
  itemPerPage: number,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>
) => {

  if(event !== undefined){
    event.preventDefault()
  }
  // Filtrer les paramètres qui ne sont pas définis ou sont vides
  const filteredParams = Object.entries(filteringData)
    .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
    .join('&') // Les concatène avec '&'
  setIsLoading(true)
  let newSortData: any[] = []
  let pageTotal: any[] = []
  try {
    const connaissementPromises = tokenTab?.map((token: string) =>
      ConnaissementServices.getFilteredTransporterConnaissementByStatus(
        token,
        currentPage,
        filteredParams,
        status
      )
        .then((response: any) => {
          newSortData = [...newSortData, ...response?.data?.content]
          pageTotal.push(response?.data?.totalPages)
        })
        .catch((error: any) => {
          if (error) {
            console.log(error)
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
          }
        })
    )

    await Promise.all([...(connaissementPromises || [])])

    const sortedData = newSortData?.sort((a: any, b: any) => b.id - a.id)
    if (filteringData.expediteur_denomination || filteringData.dateDepart) {
      const expediteur = filteringData.expediteur_denomination?.trim() ?? ''
      const inputDateDepart = filteringData.dateDepart?.trim() ?? ''

      if (expediteur?.length > 2 || inputDateDepart.length > 2) {
        const filteredData = sortedData.filter((item: any) => {
          const itemExpediteur = item?.expediteur?.denomination?.toLowerCase() || ''
              // logique pour la date de départ
        const fullDateDepart = item?.voyage?.dateDepart || ''
        const itemDateDepartFormatted = fullDateDepart.split('-').reverse().join('/') // "12/11/2024"

        // Comparaison partielle pour la date de départ
        const matchDateDepart =
          !inputDateDepart ||
          itemDateDepartFormatted.includes(inputDateDepart) || // Recherche partielle
          itemDateDepartFormatted.startsWith(inputDateDepart) // Recherche début correspond


          return (
            itemExpediteur.includes(expediteur.toLowerCase()) &&
            matchDateDepart
          )
        })

        setConnaissementData(filteredData)
        setTotalPages(Math.ceil(filteredData.length / itemPerPage) || 1)
      } else {
        setConnaissementData(sortedData)
        setTotalPages(Math.max(...pageTotal))
      }
    } 
    else {
      setConnaissementData(sortedData)
      setTotalPages(Math.max(...pageTotal))
    }
    setIsLoading(false)
    setIsFiltering(true)
    
  } catch (error) {
    setIsLoading(false)
    console.log(error)
    setIsFiltering(true)
  }
}

export const _filterConnaissement2 = async (
  // event: any | undefined,
  status: statusType,
  filteringData: any,
  setIsLoading: any,
  tokenTab: string[],
  currentPage: number,
  setIsError: React.Dispatch<
    React.SetStateAction<{
      error: boolean
      message: string
    }>
  >,
  setConnaissementData: any,
  itemPerPage: number,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>,
  sort: string,
  isEmpty: boolean,
  dataStore: UserState
) => {
  // if(event !== undefined){
  //   event.preventDefault()
  // }
  // // Filtrer les paramètres qui ne sont pas définis ou sont vides
  const filteredParams = Object.entries(filteringData)
    .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
    .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
    .join('&') // Les concatène avec '&'
  setIsLoading(true)
  let newSortData: any[] = []
  let pageTotal: any[] = []
  try {
    const connaissementPromises = tokenTab?.map((token: string) =>
      ConnaissementServices.getFilteredTransporterConnaissementByStatusSort(
        token,
        currentPage,
        filteredParams,
        status,
        sort
      )
        .then((response: any) => {
          newSortData = [...newSortData, ...response?.data?.content]
          pageTotal.push(response?.data?.totalPages)
        })
        .catch((error: any) => {
          if (error) {
            console.log(error)
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
          }
          if (error?.response?.data?.error === 'invalid_token') {
          
            const company = dataStore?.company?.find((company: any) => company.access_token === token);
            if (company) {
              
               _refreshToken(dataStore?.token, company.id_company);
            }
          }
        })
    )

    await Promise.all([...(connaissementPromises || [])])


    const sortedData = newSortData?.sort((a: any, b: any) => b.voyage?.dateDepart - a.voyage?.dateDepart)
    if (filteringData.expediteur_denomination || filteringData.dateDepart) {
      const expediteur = filteringData.expediteur_denomination?.trim() ?? ''
      const dateDepart = filteringData.dateDepart?.trim() ?? ''
      const value = filteringData.expediteur_denomination

      if (value?.length > 2 || dateDepart.length > 2) {
        const filteredData = sortedData.filter((item: any) => {
          const itemExpediteur = item?.expediteur?.denomination?.toLowerCase() || ''
          const itemDateDepart = item?.voyage?.dateDepart || ''

          return (
            itemExpediteur.includes(expediteur.toLowerCase()) &&
            itemDateDepart.includes(dateDepart.toLowerCase())
          )
        })

        setConnaissementData(filteredData)
        setTotalPages(Math.ceil(filteredData.length / itemPerPage) || 1)
      } else {
        setConnaissementData(sortedData)
        setTotalPages(Math.max(...pageTotal))
      }
    } else {
      setConnaissementData(sortedData)
      setTotalPages(Math.max(...pageTotal))
    }
    // setIsLoading(false)

    // setIsFiltering(true)
    
  } catch (error) {
    console.log(error)
  }finally{
    setIsLoading(false)
    if(!isEmpty){
      
      setIsFiltering(true)
    }
  }
}

export const _getConnaissementFiltered = async (event: any | undefined, token: any,  filteringData: any, currentPage: number, itemPerPage: number, sort: string, setConnaissementData: React.Dispatch<React.SetStateAction<any>>) => {
    if(event !== undefined){
      event.preventDefault()
    }
    let filteredParams
     //Si le status demandé fait parti de la liste, on applique le statut OFFICIALISE
     if (
       filteringData.evenementConnaissement === 'OFFICIALISE' ||
       filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
       filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
       filteringData.evenementConnaissement === 'EMBARQUE' ||
       filteringData.evenementConnaissement === 'MODIFIE' ||
       filteringData.evenementConnaissement === 'TRANSFERE' ||
       filteringData.evenementConnaissement === 'SAISIE'
     ) {
       const newData = {
         ...filteringData,
         evenementConnaissement: 'OFFICIALISE',
       }

       // Filtrer les paramètres qui ne sont pas définis ou sont vides
       filteredParams = Object.entries(newData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     } else {
       filteredParams = Object.entries(filteringData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     }

    //  setIsLoading(true)

    try{

      // const responseFilteredConnaissement = await ConnaissementService.getFilteredConnaissement(token, currentPage, filteredParams, itemPerPage)
      const responseFilteredConnaissement = await ConnaissementServices.getFilteredConnaissementSort(token, currentPage, filteredParams, itemPerPage, sort)


  let sortedData: any = []
  //Si le status demandé fait parti de la liste, on refiltre les données par un de ces statuts
  if (
    filteringData.evenementConnaissement === 'OFFICIALISE' ||
    filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
    filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
    filteringData.evenementConnaissement === 'EMBARQUE' ||
    filteringData.evenementConnaissement === 'MODIFIE' ||
    filteringData.evenementConnaissement === 'TRANSFERE' ||
    filteringData.evenementConnaissement === 'SAISIE'
  ) {


    const officialiseData = responseFilteredConnaissement?.data?.content?.filter(
      (data: any) =>
        data.dernierEtat?.evenementConnaissement === filteringData.evenementConnaissement
    )
    console.log(officialiseData)

    sortedData = officialiseData?.sort((a: any, b: any) => b.id - a.id)
  } else {
    sortedData = responseFilteredConnaissement?.data?.content?.sort(
      (a: any, b: any) => b.id - a.id
    )
  }
  

  if (filteringData?.dateDepart || filteringData?.dateArrivee) {
    const inputDateDepart = filteringData.dateDepart?.trim() ?? ''
    const inputDateArrivee = filteringData.dateArrivee?.trim() ?? ''

    if (inputDateArrivee?.length > 2 || inputDateDepart.length > 2) {
      const filteredData = sortedData.filter((item: any) => {
        // logique pour la date de départ
        const fullDateDepart = item?.voyage?.dateDepart || ''
        const itemDateDepartFormatted = fullDateDepart.split('-').reverse().join('/') // "12/11/2024"

        // Comparaison partielle pour la date de départ
        const matchDateDepart =
          !inputDateDepart ||
          itemDateDepartFormatted.includes(inputDateDepart) || // Recherche partielle
          itemDateDepartFormatted.startsWith(inputDateDepart) // Recherche début correspond

        // logique pour la date d'arrivée
        const fullDateArrivee = item?.voyage?.periple?.filter(
          (periple: any) => periple?.ileArrivee.nom === item?.ileArrivee?.nom
        )[0]
        const itemDateArriveeFormatted = fullDateArrivee.dateArrivee
          .split('-')
          .reverse()
          .join('/') // "12/11/2024"

        // Comparaison partielle pour la date d'arrivée
        const matchDateArrivee =
          !inputDateArrivee ||
          itemDateArriveeFormatted.includes(inputDateArrivee) || // Recherche partielle
          itemDateArriveeFormatted.startsWith(inputDateArrivee) // Recherche début correspond

      
        return (
          matchDateArrivee && matchDateDepart
        )
      })
      setConnaissementData(filteredData)
      // setTotalPages(responseFilteredConnaissement?.data?.totalPages)
    } else {
      setConnaissementData(sortedData)
      // setTotalPages(responseFilteredConnaissement?.data?.totalPages)
    }
  } else {
    setConnaissementData(sortedData)
    // setTotalPages(responseFilteredConnaissement?.data?.totalPages)
    console.log("hello")
  }

      // setIsLoading(false)
      // setIsFiltering(true)
    }catch(error){
      // setIsLoading(false)
      console.log(error)
      // setIsFiltering(true)
    }
  }

export const _handleFilterConnaissement = (
  event: any,
  connaissementData: ResultConnaissementType[],
  setConnaissementData: React.Dispatch<React.SetStateAction<ResultConnaissementType[]>>,
  setIsLoading: any,
  setIsError: React.Dispatch<React.SetStateAction<{ error: boolean; message: string }>>,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  currentPage: number,
  itemPerPage: number,
  status: statusType,
  tokenTab: string[],
  setIsErrorConnaiss: any,
  dataStore: any
) => {
  const value = event?.target?.value
  if (value?.length > 2) {
    const filteredData = connaissementData.filter((item: any) => {
      return (
        item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
        item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
        item?.expediteur?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
        item?.dernierEtat?.evenementConnaissement
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
        item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
        item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
        item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
      )
    })

    setConnaissementData(filteredData)
  }
  if (value.length === 0) {
    _connaissementDataTable(
      status,
      currentPage,
      setTotalPages,
      itemPerPage,
      setIsLoading,
      tokenTab,
      setIsError,
      setConnaissementData,
      setIsErrorConnaiss,
      dataStore
    )
  }
}

// export const _handleFilterConnaissement2 = (
//   event: any,
//   connaissementData: ResultConnaissementType[],
//   setConnaissementData: React.Dispatch<React.SetStateAction<ResultConnaissementType[]>>,
//   setIsLoading: any,
//   setIsError: React.Dispatch<React.SetStateAction<{ error: boolean; message: string }>>,
//   setTotalPages: React.Dispatch<React.SetStateAction<number>>,
//   currentPage: number,
//   itemPerPage: number,
//   status: statusType,
//   tokenTab: string[],
//   dataStore: any,
//   filteringData: any,
//   setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>,
//   sortConfig: any,
//   isEmpty: boolean
// ) => {
//   const value = event?.target?.value
//   if (value?.length > 2) {
//     const filteredData = connaissementData.filter((item: any) => {
//       return (
//         item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.expediteur?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.dernierEtat?.evenementConnaissement
//           .toLowerCase()
//           .includes(value.toLowerCase()) ||
//         item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
//         item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
//       )
//     })

//     setConnaissementData(filteredData)
//   }
//   if (value.length === 0) {
//     _filterConnaissement2(
//       // event,
//       status,
//       filteringData,
//       setIsLoading,
//       tokenTab,
//       currentPage,
//       setIsError,
//       setConnaissementData,
//       itemPerPage,
//       setTotalPages,
//       setIsFiltering,
//       sortConfig,
//       isEmpty,
//       dataStore
//     )
//   }
// }

export const _deleteBrouillonConnaissement = async (
  token: string,
  id: number,
  setIsError: any,
  connaissementDataTable: any,
  currentPage: number,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  itemPerPage: number,
  toggleShowDeleteSuccess: any,
  handleCloseDeleteModal: any,
  toggleShowOrderError: any

) => {
  setIsError({
    error: false,
    message: '',
  })
  try {
    const response = await ConnaissementServices.deleteBrouillon(token, id)

    if (response.status === 204) {
      connaissementDataTable(currentPage, setTotalPages, itemPerPage)
      toggleShowDeleteSuccess()
      handleCloseDeleteModal()
    }
  } catch (error: any) {
    console.log(error)
    setIsError({
      error: true,
      message: error?.response?.data?.message,
    })
    toggleShowOrderError()
  }
}

export const _getPDFConnaissementByEvenement = (
  token: string,
  id: number,
  idEven: number,
  setPdfData: any, ) => {
  try{
     ConnaissementServices.getPDFConnaissementToRead(
      token,
      id,
      idEven,
      setPdfData,
    )

  }catch(error){
    console.log(error)
  }
}

  //Merge pdfs in one
  const mergeAndPrintPDFs = async (pdfBlobs: Blob[], setPdfData: React.Dispatch<React.SetStateAction<any>>) => {
   
    try{

      
      const mergedPdf = await PDFDocument.create();
      
    for (const pdfBlob of pdfBlobs) {
      const arrayBuffer = await pdfBlob.arrayBuffer();
      const pdfToMerge = await PDFDocument.load(arrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdfToMerge, pdfToMerge.getPageIndices());
      copiedPages.forEach(page => mergedPdf.addPage(page));
    }
  
    const mergedPdfBlob = new Blob([await mergedPdf.save()], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(mergedPdfBlob);
    setPdfData(fileURL)
    }catch(error){
      console.log(error)
    }

  };

//Multi Print PDF
export const _printMultiPDFConnaissementByEvenement = async (
    token: string,
    checkedMultiConnaissement: any, setPdfData: React.Dispatch<React.SetStateAction<any>>) => {
    try{

      
        const response = await ConnaissementServices.downloadPDFs(
        token,
        checkedMultiConnaissement
        
      )
      mergeAndPrintPDFs(response, setPdfData)

    }catch(error){
      console.log(error)
    }
  }

//Multi Download PDF
export const _getMultiPDFConnaissementByEvenement =  (
  token: string,
  checkedMultiConnaissement: any,
  setPdfData: any

) => {
  try{
  
        const response = checkedMultiConnaissement?.map((item: any) =>
          ConnaissementServices.getPDFConnaissement(
          token,
          item?.id,
          item?.dernierEtat?.id,
          item?.numero,
          setPdfData,
        ))
        Promise.all([
            ...(response || [])
          ]);
  
      }catch(error){
        console.log(error)
      }
}


/////////////////////
// nomenclature douanière
/////////////////////

export const _getNomenclature = async (token: string, designation: string, setData: React.Dispatch<SetStateAction<NomenclatureType | undefined>>) => {

  try{
    const response = await NomenclaturesService.getNomenclatureList(token, designation)
    setData(response)

  }catch(error){
    console.log(error)
  }

}