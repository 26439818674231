import axios from "axios";


const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT
class CompaniesService {
 
  getCompanies(token: string | null, page: number) {
    return axios.get(API_URL_TOTARA + '/api/v1/companies?page=' + page, {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
  }

  addCompany(token: string | null, data: number) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/companies',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json', 
        'Authorization': 'Bearer ' + token
      },
      data : data
    };
  return axios.request(config)
  }

  updateCompany(token: string | null, data: number, id: number) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/companies/' + id,
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json', 
        'Authorization': 'Bearer ' + token
      },
      data : data
    };
  return axios.request(config)
  }
}

const companyServiceInstance = new CompaniesService();
export default companyServiceInstance;