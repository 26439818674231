import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import userStore from '../../stores/userStore'
import { useOutletContext } from 'react-router-dom'
import { _filterConnaissement } from '../../utils/api/apiControlerFunctions'

export default function TransporterFilterTab({ billOfilterProps }: { billOfilterProps: any }) {
  const {
    filteringData,
    setFilteringData,
    isFiltering,
    isEmpty,
    isLoading,
  } = billOfilterProps

  const { naviresData } = useOutletContext<any>()

  const dataStore = userStore((state: any) => state)

  return (
    <React.Fragment>
      <thead className=''>
        <tr>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='N°Connaisse...'
              name='numeroConnaissement'
              value={filteringData.numeroConnaissement || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  numeroConnaissement: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='Compagnie'
              name='expediteur_denomination'
              value={filteringData.expediteur_denomination || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  expediteur_denomination: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='Client'
              name='destinataire'
              value={filteringData.destinataire || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  destinataire: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Select
              name='idNavire'
              value={filteringData.idNavire || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  idNavire: e.currentTarget.value,
                  // nomNavire: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
              aria-label='zone'
              className=''
            >
              <option value='' className='text-ui-second'>
                Navire
              </option>
              {naviresData?.map((navire: any, index: any) => (
                <option key={index} value={navire?.id}>
                  {navire?.name}
                </option>
              ))}
            </Form.Select>
          </th>

          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='Date départ'
              name='date_depart'
              value={filteringData.dateDepart || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  dateDepart: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>

          <th className='responsive-font-small p-1 p-sm-2'>
            <Button
              type='submit'
              className='d-flex align-items-center font-85 bg-remora-primary border-remora-primary'
              disabled={isEmpty}
            >
              {isLoading ? (
                <Spinner size='sm' animation='border' role='status' className='me-2' />
              ) : (
                <i className='ri-search-line me-0  me-md-2'></i>
              )}
              <span className='d-none d-md-block'>Rechercher</span>{' '}
            </Button>
            {isFiltering && (
              <span
                className='pointer'
                onClick={() => {
                  setFilteringData({
                    numeroConnaissement: '',
                    expediteur_denomination: '',
                    destinataire: '',
                    idNavire: '',
                    dateDepart: '',
                   
                  })
                }}
              >
                <i className='ri-close-line fs-5'></i>
                <u>Réinitialiser</u>
              </span>
            )}
          </th>
        </tr>
      </thead>
      {/* <thead className=' mobile-table-head'>
        <tr>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='N°'
              name='numeroConnaissement'
              value={filteringData.numeroConnaissement || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  numeroConnaissement: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Compagnie'
                name='expediteur_denomination'
                value={filteringData.expediteur_denomination || ''}
                onChange={(e) => {
                  const updatedFilteringData = {
                    ...filteringData,
                    expediteur_denomination: e.currentTarget.value,
                  }
                  setFilteringData(updatedFilteringData)
                }}
              />
            </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='Client'
              name='destinataire'
              value={filteringData.destinataire || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  destinataire: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Select
              name='idNavire'
              value={filteringData.idNavire || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  idNavire: e.currentTarget.value,
                  nomNavire: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
              aria-label='zone'
              className=''
            >
              <option value='' className='text-ui-second'>
                Navire
              </option>
              {naviresData?.map((navire: any, index: any) => (
                <option key={index} value={navire?.name}>
                  {navire?.name}
                </option>
              ))}
            </Form.Select>
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            {' '}
            <Form.Control
              className=' border'
              type='text'
              autoComplete='on'
              placeholder='Date départ'
              name='date_depart'
              value={filteringData.dateDepart || ''}
              onChange={(e) => {
                const updatedFilteringData = {
                  ...filteringData,
                  dateDepart: e.currentTarget.value,
                }
                setFilteringData(updatedFilteringData)
              }}
            />
          </th>
          <th className='responsive-font-small p-1 p-sm-2'>
            <Button
              type='submit'
              className='d-flex align-items-center font-85 bg-remora-primary border-remora-primary'
              disabled={isEmpty}
            >
              {isLoading ? (
                <Spinner size='sm' animation='border' role='status' className='me-2' />
              ) : (
                <i className='ri-search-line me-0  me-md-2'></i>
              )}
              <span className='d-none d-md-block'>Rechercher</span>{' '}
            </Button>
            {isFiltering && (
              <span
                className='pointer'
                onClick={() => {
                  setFilteringData({
                    numeroConnaissement: '',
                    expediteur_denomination: '',
                    destinataire: '',
                    idNavire: '',
                    evenementConnaissement: '',
                    dateDepart: '',
                    nomIleArrivee: '',
                    dateArrivee: '',
                  })
                }}
              >
                <i className='ri-close-line fs-5'></i>
                <u>Réinitialiser</u>
              </span>
            )}
          </th>
        </tr>
      </thead> */}
    </React.Fragment>
  )
}
