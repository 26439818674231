import UserService from '../../services/user/UserService'
import CompaniesService from '../../services/companies/CompaniesService'
import OrdersService from '../../services/orders/OrdersService'
import { UserType } from '../../definitions/UserType'
import React, { SetStateAction } from 'react'
import { CompanyType } from '../../definitions/CompanyType'
import { OrderType, ProductDataType } from '../../definitions/OrderType'
import { errorType } from '../../definitions/errorType'
import { UserState } from '../../stores/userStore'
import { useNavigate } from 'react-router-dom'
import { _errorForgotPass } from '../errors'
import ConnaissementServices from '../../services/connaissements/ConnaissementServices'
import authServiceInstance from '../../services/Auth/AuthService'

/////////////////////////
///users
////////////////////////

export const _getUsersData = async (
  token: string | null,
  setUserData: React.Dispatch<SetStateAction<UserType[] | undefined>> | undefined,
  setIsLoading: React.Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true)
  try {
    let page = 1
    let allUsers: any = []
    let hasMorePages = true

    while (hasMorePages) {
      const response = await UserService.getUsers(token, page)
      allUsers = [...allUsers, ...response.data.data]

      if (response.data.meta.current_page < response.data.meta.last_page) {
        page++
      } else {
        hasMorePages = false
      }
    }

    // setUserData(response?.data.data)
    if (setUserData) {
      setUserData(allUsers)
    }
    setIsLoading(false)
  } catch (error: any) {
    console.log(error)
  }
}

export const _forgotPassword = async (
  email: string,
  toastData: any,
  setToastData: React.Dispatch<React.SetStateAction<any>>,
  toggleShowAll: () => void,
  handleCloseForgotPassWord: () => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true)
  try {
    await UserService.forgotUserPassword(email)
    toggleShowAll()
    setToastData({
      ...toastData,
      bg: 'success',
      message: 'Email envoyé',
      icon: 'checkbox-circle',
    })
    handleCloseForgotPassWord()
    setIsLoading(false)
  } catch (error: any) {
    console.log(error)
    toggleShowAll()
    setToastData({
      ...toastData,
      bg: 'danger',
      message: _errorForgotPass(error?.response?.data?.message),
      icon: 'error-warning',
    })
    handleCloseForgotPassWord()
    setIsLoading(false)
  }
}

export const _resetUserPassword = async (
  data: any,
  toastData: any,
  setToastData: React.Dispatch<React.SetStateAction<any>>,
  toggleShowAll: () => void,
  navigate: any,
  formAttribute: any,
  setFormAttribute: any
) => {
  setFormAttribute({
    ...formAttribute,
    isLoadingAuth: true,
  })
  try {
    await UserService.resetPassword(data)
    console.log('reset')
    toggleShowAll()
    setToastData({
      ...toastData,
      bg: 'success',
      message: 'Votre mot de passe a été réinitialisé, vous allez être redirigé',
      icon: 'checkbox-circle',
    })
    setFormAttribute({
      ...formAttribute,
      isLoadingAuth: false,
    })
    setTimeout(() => {
      navigate('/connexion')
    }, 7000)
  } catch (error: any) {
    // console.log(error)
    toggleShowAll()
    setToastData({
      ...toastData,
      bg: 'danger',
      message: _errorForgotPass(error?.response?.data?.message),
      icon: 'error-warning',
    })
    setFormAttribute({
      ...formAttribute,
      isLoadingAuth: false,
    })
  }
}

////////////////////////
///companies
///////////////////////

export const _getCompaniesData = async (
  token: string | null,
  setCompaniesData: React.Dispatch<SetStateAction<CompanyType[]>>,
  setIsLoading: React.Dispatch<SetStateAction<boolean>>
) => {
  setIsLoading(true)

  try {
    let page = 1
    let allCompanies: any = []
    let hasMorePages = true

    while (hasMorePages) {
      const response = await CompaniesService.getCompanies(token, page)
      allCompanies = [...allCompanies, ...response.data.data]

      if (response.data.meta.current_page < response.data.meta.last_page) {
        page++
      } else {
        hasMorePages = false
      }
    }
    setCompaniesData(allCompanies)
  } catch (error) {
    setIsLoading(false)
    console.log(error)
  }finally{
    setIsLoading(false)
  }
}

////////////////////////
///Orders
///////////////////////

//formatte orders
export const _transformDataToNested = (data: any) => {
  const result: any = []

  data?.forEach((item: any) => {
    result.push({
      expediteur: {
        denomination: item.expediteur_denomination,
        telephone: item.expediteur_telephone,
        mail: item.expediteur_mail,
        numeroTahiti: item.expediteur_numeroTahiti,
      },

      id: item.id,
      id_connaissement: item.id_connaissement,
      id_company: item.id_company,
      numeroVoyage: item.numeroVoyage,
      paiement: item.paiement,
      ileDepart: item.ileDepart,
      ileArrivee: item.ileArrivee,
      lieuArrivee: item.lieuArrivee,
      date_etl: item.date_etl,
      date_creation: item.date_creation,
      dateModif: item.dateModif,
      statusRevatua: item.statut_revatua,
      referenceHorsRevatua: item.referenceHorsRevatua,
      destinataire: {
        denomination: item.destinataire_denomination,
        telephone: item.destinataire_telephone,
        mail: item.destinataire_mail,
        numeroTahiti: item.destinataire_numeroTahiti,
      },
      items: item.items,
    })
  })

  return result
}

//get orders
export const _getOrdersData = async (
  token: string | null,
  setDataOrder: React.Dispatch<SetStateAction<OrderType[]>>,
  setIsLoading: React.Dispatch<SetStateAction<boolean>>,
  setErrorOrderMessage: any,
  itemPerPage: number
) => {
  setIsLoading(true)
  try {
    let page = 1
    let allOrders: any = []
    let hasMorePages = true

    while (hasMorePages) {
      const response = await OrdersService.getOrders(token, page, itemPerPage)
      allOrders = [...allOrders, ..._transformDataToNested(response.data.data)]
      
      if (response.data.meta.current_page < response.data.meta.last_page) {
        page++
      } else {
        hasMorePages = false
      }
    }

    setDataOrder(allOrders)
    setIsLoading(false)
    setErrorOrderMessage({
      message: '',
      isError: false,
    })
  } catch (error: any) {
    setIsLoading(false)
    setErrorOrderMessage({
      message: error?.message,
      isError: true,
    })
    console.log(error)
  }
}

//filter orders
export const _filteredOrder = async (
  token: any,
  filteringData: any,
  currentPage: number,
  itemPerPage: number
) => {
  try {
    await OrdersService.filteredOrder(token, filteringData, currentPage, itemPerPage)
  } catch (error) {
    console.log(error)
  }
}

//switch status order : A_PLANIFIER/A_DEPLANIFIER
export const _switchStatus = async (
  token: string | null,
  status: string,
  id: number | null,
  setErrorOrderMessage: any,
  dataOrder: any,
  setDataOrder: any,
  toggleShowErrorOrder: any
) => {
  const bodyData = {
    statut_revatua: status,
  }
  setErrorOrderMessage({
    error: false,
    message: '',
  })

  try {
    const orderPromises = await OrdersService.updateOrder(token, bodyData, id)

    if (orderPromises.status === 200) {
      const order = dataOrder?.map((order: any) => {
        if (order.id === id) {
          return {
            ...order,
            statusRevatua: status,
          }
        }
        return order
      })
      setDataOrder(order)
    }
  } catch (error: any) {
    console.log(error)
    const messageError = JSON.parse(error?.request.responseText)?.data?.statut_revatua
      ? JSON.parse(error?.request.responseText)?.data?.statut_revatua[0]
      : error?.response?.data?.data?.message
    toggleShowErrorOrder()
    setErrorOrderMessage({
      error: true,
      message: messageError,
    })
  }
}

//Get orders id from connaissement id
export const _getOrdersByOneIdBill = async (
  id: number | undefined,
  idsOrder: number[],
  toggleShowOrderError: () => void,
  dataStore: UserState,
  setIsError: React.Dispatch<SetStateAction<errorType>>,
  setIsLoading: React.Dispatch<SetStateAction<boolean>>,
  setIsClickable: React.Dispatch<SetStateAction<boolean>>
) => {
  setIsError({
    error: false,
    message: '',
  })
  setIsLoading(true)
  setIsClickable(true)
  try {
    const responseOrder = await OrdersService.getOrdersByIdConnaissement(dataStore.token, id)
      .then((response: any) => {
        response?.data?.data?.map((order: any) => {
          setIsLoading(false)
          setIsClickable(false)
          return idsOrder.push(order?.id)
        })
      })
      .catch((error: any) => {
        console.log(error)
        setIsLoading(false)
        setIsClickable(true)
        setIsError({
          error: true,
          message: error?.response?.data?.message,
        })
        toggleShowOrderError()
      })
  } catch (error) {
    console.log(error)
    setIsLoading(false)
    setIsClickable(false)
  }
}

// reset order in DB
export const _resetOrderInDb = (
  token: string,
  idsOrder: number[],
  toggleShowOrderError: () => void,
  setIsError: React.Dispatch<SetStateAction<errorType>>,
  setShowOrderError: React.Dispatch<SetStateAction<boolean>>
) => {
  //reset error message
  setIsError({
    error: false,
    message: '',
  })
  //close the error pop up
  setShowOrderError(false)
  //new order(s) values
  const bodyData = {
    statut_revatua: 'A_PLANIFIER',
    numeroVoyage: null,
    id_connaissement: null,
  }

  try {
    //update the order
    idsOrder?.map((id: number) => OrdersService.updateOrder(token, bodyData, id))
    console.log('reset')
  } catch (error: any) {
    console.log(error)
    if (error) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
      toggleShowOrderError()
    }
  }
}

export const _getAlertNotification = async (
  token: string | null,
  setNotifications: React.Dispatch<SetStateAction<any>>
) => {
  try {
    const response = await OrdersService.getNotification(token)
    setNotifications(response?.data?.data)
  } catch (error) {
    console.log(error)
  }
}
export const _patchAlertNotification = async (
  token: string | null,
  id: number,
  setNotifications: React.Dispatch<SetStateAction<any>>
) => {
  try {
    const response = await OrdersService.patchNotification(token, id)
    console.log(response)
    // setNotifications(response?.data?.data)
  } catch (error) {
    console.log(error)
  }
}
export const _patchNotification = async (token: string | null, id: number, setNotifications: React.Dispatch<SetStateAction<any>>, setToastData:  React.Dispatch<SetStateAction<any>>,  toggleShowAll: () => void) => {
  try {
    const response = await OrdersService.patchNotification(token, id)
    _getAlertNotification(token, setNotifications)
    console.log(response)
setToastData((prev: any) => ({
      ...prev,
      bg: 'success',
      message: response?.data?.message,
      icon: 'checkbox-circle',
    }))
    toggleShowAll()
  }
  catch (error) {
    console.log(error)
  }
}

////////////////////////
///Products (Orders)
///////////////////////

//filter products
export const _filteredItem = async (token: string | null, filteringData: any, id: number) => {
  try {
    await OrdersService.filteredItem(token, filteringData, id)
  } catch (error) {
    console.log(error)
  }
}

//add a producting existing order
export const _handleAddproduct = async (token: string | null, orderData: any) => {
  try {
    await OrdersService.addItemsOrder(token, orderData)
  } catch (error) {
    console.log(error)
  }
}

//edit a product
export const _handleUpdateProduct = async (
  token: string | null,
  orderData: ProductDataType,
  id: number,
  setIsError: React.Dispatch<SetStateAction<errorType>>,
  handleCloseUpdateProductModal: () => void,
  setSelectedOrder: any,
  updatedProducts: any,
  setDataOrder: any,
  updatedOrder: any
) => {
  setIsError({
    error: false,
    message: '',
  })
  const possibleFields = [
    'detail_nbColis',
    'detail_description',
    'detail_codeSH',
    'detail_codeTarif',
    'detail_stockage',
    'detail_poids',
    'detail_referenceExterne',
  ]
  try {
    await OrdersService.updateItemsOrder(token, orderData, id)
    setIsError({
      error: false,
      message: '',
    })

    // Mise à jour de l'état selectedOrder avec les produits mis à jour
    setSelectedOrder((prevOrder: any) => ({
      ...prevOrder,
      items: updatedProducts,
    }))
    // Mise à jour des factures en local
    setDataOrder(updatedOrder)

    handleCloseUpdateProductModal()
  } catch (error: any) {
    console.log(error)
    const customMessage = error?.response?.data?.error

    // Expression régulière pour capturer les champs sous la forme "variable = ?"
    const regex = /`(\w+)` = \?/g
    let match
    const fieldsInError = []

    // Chercher toutes les occurrences de "variable = ?"
    while ((match = regex.exec(customMessage)) !== null) {
      const fieldName = match[1]
      if (possibleFields.includes(fieldName)) {
        fieldsInError.push(fieldName)
      }
    }

    if (fieldsInError.length > 0) {
      setIsError({
        error: true,
        message: `Les champs suivants causent l'erreur: ${fieldsInError.join(', ')}`,
        // message: "La colonne 'detail_stockage' est impliquée dans l'erreur."
      })
    } else {
      setIsError({
        error: true,
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message === 'Network Error'
          ? 'Une erreur est survenue. Vérifiez votre connexion et réessayer.'
          : error?.message,
      })
    }
  }
}


//////////////////
//auth
//////////////////

export const _userLogout = async (token: string | null) => {
    try{
     const response = await authServiceInstance.logout(token)
     console.log(response)
    }catch(error){
      console.log(error)
    }
}
